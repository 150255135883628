import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VTooltip from "v-tooltip";

import "@/assets/scss/style.css";
import "@/assets/scss/custom.css";
import "@/assets/fonts/fontawesome/css/all.css";

import VueSvgInlinePlugin from "vue-svg-inline-plugin";
// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);

Vue.config.productionTip = false;

Vue.use(VTooltip);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
