<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  created() {
    this.featchSettings();
  },
  mounted() {
    if (!this.isIssetCar) {
      this.$router.push({ name: "index" });
    }
  },
  computed: {
    ...mapGetters(["isIssetCar"]),
  },
  methods: {
    ...mapActions(["featchSettings"]),
  },
};
</script>
