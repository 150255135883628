import config from "../config";
import instance from "./index";

const FCARS_URL = `${config.API_URL}/cars/get`;

export default {
  getTempCars() {
    return instance.get(`${FCARS_URL}`);
  },
};
