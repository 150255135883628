import config from "../config";
import instance from "./index";

const SETTINGS_URL = `${config.API_URL}/settings/get`;

export default {
  getSettings() {
    return instance.get(`${SETTINGS_URL}`);
  },
};
