import axios from "axios";
import { throttleAdapterEnhancer } from "axios-extensions";
import router from "../router";

const CACHE_TIME = 2 * 1000;

let instance = axios.create({
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, CACHE_TIME),
});

const notifyAboutError = (error) => {
  var event = new CustomEvent("app-network-error", { detail: error });
  window.dispatchEvent(event);
};

const notifyInfo = (data) => {
  var event = new CustomEvent("app-info", { detail: data });
  window.dispatchEvent(event);
};

instance.interceptors.response.use(
  (response) => {
    if (response.data.errcode !== 0) {
      notifyAboutError(response.data);
    } else {
      notifyInfo(response.data);
    }
    // console.log("response: ", response);
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !/login/.test(error.response.config.url)
    ) {
      router.push("/login");
    } else {
      if (error.response && error.response.status >= 400) {
        notifyAboutError(error.response.data);
      } else {
        notifyAboutError(error);
      }
      return Promise.reject(error);
    }
  }
);

export default instance;
