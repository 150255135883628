import config from "../config";
import instance from "./index";

const MARKS_URL = `${config.API_URL}/marks/get`;

export default {
  getMarks() {
    return instance.get(`${MARKS_URL}`);
  },
};
